import RollbarConstructor from 'rollbar/src/browser/core'
import telemeter from 'rollbar/src/telemetry'
// import instrumenter from 'rollbar/src/browser/telemetry'
import wrapGlobals from 'rollbar/src/browser/wrapGlobals'
import scrub from 'rollbar/src/scrub'
import truncation from 'rollbar/src/truncation'

// https://github.com/rollbar/rollbar.js/pull/817
RollbarConstructor.setComponents({
  telemeter,
  // instrumenter,
  // polyfillJSON,
  wrapGlobals,
  scrub,
  truncation
})

const environment = process.env.NEXT_PUBLIC_VERCEL_ENV || 'development'

const isServer = typeof window === 'undefined'

const rollbar = new RollbarConstructor({
  enabled: environment === 'production',
  accessToken: isServer
    ? '0817a8cbd1f04701ac287b70c9a23eda'
    : '0253745b105c4ff0bfec5d754b67399f',
  hostSafeList: [
    'customers.bft.com',
    'bft.com',
    'www.bestfoodtrucks.com',
    'customers.bestfoodtrucks.com',
    'api.bestfoodtrucks.com',
    'bestfoodtrucks.com'
  ],
  captureUncaught: true,
  captureUnhandledRejections: isServer,
  autoInstrument: true,
  ignoredMessages: ['__gCrWeb'],
  payload: {
    environment,
    client: {
      javascript: {
        source_map_enabled: true,
        guess_uncaught_frames: true,
        code_version: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA
      }
    }
  }
})

export const setReportingUser = (
  user: { id: number; email?: string } | null
) => {
  if (user) {
    rollbar.configure({
      payload: {
        person: {
          id: user.id,
          email: user.email
        }
      }
    })
  } else {
    rollbar.configure({
      payload: {
        person: undefined
      }
    })
  }
}

export default rollbar
