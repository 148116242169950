import React, { createContext, useContext, useState } from 'react'
import Modal from 'react-modal'
import Loading from 'components/Loading'

type WithBlockUI = <T>(callback: () => Promise<T>) => Promise<T>

export type BlockUiActions = {
  startBlockUi: () => void
  stopBlockUi: () => void
  withBlockUi: WithBlockUI
}

const BlockUiContext = createContext<BlockUiActions>({
  startBlockUi: () => null,
  stopBlockUi: () => null,
  withBlockUi: async (callback) => callback()
})

export const BlockUiProvider: React.FC = ({ children }) => {
  const [open, setOpen] = useState<number>(0)
  const startBlockUi = () => setOpen((s) => s + 1)
  const stopBlockUi = () => setOpen((s) => s - 1)

  const withBlockUi: WithBlockUI = async (callback) => {
    try {
      startBlockUi()
      return await callback()
    } finally {
      stopBlockUi()
    }
  }
  const value = {
    startBlockUi,
    stopBlockUi,
    withBlockUi
  }
  return (
    <BlockUiContext.Provider value={value}>
      {children}
      {open > 0 && <BlockUiLoader />}
    </BlockUiContext.Provider>
  )
}

export function useBlockUi(): BlockUiActions {
  return useContext(BlockUiContext)
}

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '20px',
    borderWidth: 0,
    borderRadius: '10px'
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.53)',
    zIndex: 1000
  }
}

function BlockUiLoader() {
  return (
    <Modal contentLabel="Loading..." style={modalStyles} isOpen>
      <div>
        <Loading />
      </div>
    </Modal>
  )
}
