import React, { createContext, useState } from 'react'
import Button, { ButtonStyle } from 'components/ui/Button'
import Modal from 'react-modal'
import cn from 'classnames'

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderWidth: 0,
    margin: 0,
    padding: 0,
    overflow: 'hidden'
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.53)',
    zIndex: 1000
  }
}

export type ConfirmArgs = {
  title?: string
  message: string | React.ReactElement
  okButton?: string
  cancelButton?: string
  albertsons?: boolean
  color?: ButtonStyle['color']
  size?: 'sm' | 'md' | 'lg'
}

type ConfirmCallbacks = {
  onCancel: () => void
  onConfirm: () => void
}

type ConfirmDialogContextType = {
  openConfirmation: (args: ConfirmArgs) => Promise<boolean>
  closeConfirmation: () => void
}

export const ConfirmDialogContext = createContext<ConfirmDialogContextType>({
  openConfirmation: async () => false,
  closeConfirmation: () => {
    // do nothing
  }
})

export const ConfirmDialogProvider: React.FC = ({ children }) => {
  const [open, setOpen] = useState<boolean>(false)
  const [confirmationArgs, setConfirmationArgs] = useState<ConfirmArgs | null>(
    null
  )
  const [
    confirmCallbacks,
    setConfirmationCallbacks
  ] = useState<ConfirmCallbacks | null>(null)
  const value = {
    openConfirmation: async (args: ConfirmArgs): Promise<boolean> => {
      return new Promise((resolve) => {
        setConfirmationArgs(args)
        setConfirmationCallbacks({
          onConfirm: () => {
            setOpen(false)
            resolve(true)
          },
          onCancel: () => {
            setOpen(false)
            resolve(false)
          }
        })
        setOpen(true)
      })
    },
    closeConfirmation: () => {
      setOpen(false)
    }
  }
  return (
    <ConfirmDialogContext.Provider value={value}>
      {children}
      {open && <ConfirmDialog {...confirmationArgs!} {...confirmCallbacks!} />}
    </ConfirmDialogContext.Provider>
  )
}

type ConfirmDialogProps = ConfirmArgs & ConfirmCallbacks

function ConfirmDialog({
  title = 'Are you sure?',
  message,
  okButton = 'Confirm',
  cancelButton = 'Cancel',
  color = 'primary',
  onCancel,
  onConfirm,
  albertsons = false,
  size = 'sm'
}: ConfirmDialogProps) {
  return (
    <Modal style={modalStyles} isOpen>
      <div className="rounded-lg text-left overflow-hidden shadow-xl max-w-lg w-full">
        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div className="flex items-start">
            <div className="mt-3 sm:mt-0 sm:ml-4 sm:text-left">
              <h3
                className={cn({
                  'text-albertsons-neutral1': albertsons,
                  'text-gray-900': !albertsons,
                  'text-lg leading-6 font-medium': size === 'sm',
                  'text-3xl leading-10 font-bold': size === 'md'
                })}
              >
                {title}
              </h3>
              <div className="mt-2">
                <p
                  className={cn('text-gray-500', {
                    'text-albertsons-neutral1': albertsons,
                    'text-gray-500': !albertsons,
                    'text-sm': size === 'sm',
                    'text-base': size === 'md'
                  })}
                >
                  {message}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className={cn('px-4 py-3 sm:px-6 flex justify-end', {
            'bg-albertsons-neutral7': albertsons,
            'bg-gray-100': !albertsons
          })}
        >
          <div className="sm:w-auto w-1/2">
            <Button
              color="secondary"
              isAlbertsons={albertsons}
              onClick={onCancel}
              variant="outline"
              fullWidth
            >
              {cancelButton}
            </Button>
          </div>
          <div className="ml-3 sm:w-auto w-1/2">
            <Button
              color={color}
              isAlbertsons={albertsons}
              onClick={onConfirm}
              variant="contained"
              fullWidth
            >
              {okButton}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
