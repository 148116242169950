import React, { useImperativeHandle, useRef } from 'react'
import cn from 'classnames'
import { ExclamationCircleIcon } from '@heroicons/react/solid'

export type Props = {
  name: string
  label?: React.ReactElement | string
  autoComplete?: string
  description?: string | React.ReactElement | null
  placeholder?: string
  error?: string | null
  fullWidth?: boolean
  forwardedRef?: React.Ref<HTMLInputElement>
  children?: React.ReactElement | string | null
  iconLeft?: React.ReactElement
  iconRight?: React.ReactElement
  inputAdornment?: string
  inputRightAdornment?: string
  step?: string
  labelSize?: 'small' | 'base'
} & Omit<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
  'ref'
> & { ref?: React.Ref<HTMLInputElement> }

const TextField = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      label,
      name,
      description,
      placeholder,
      error,
      type,
      fullWidth = true,
      iconLeft,
      iconRight,
      inputAdornment,
      inputRightAdornment,
      children,
      labelSize = 'base',
      autoComplete,
      ...inputProps
    },
    forwardedRef
  ) => {
    const inputRef = useRef<HTMLInputElement>(null)
    useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(
      forwardedRef,
      () => inputRef.current
    )
    // for scroll on focus in add item modal

    return (
      <div className={cn('relative', { 'w-full': fullWidth })}>
        {label && (
          <label
            className={cn('block font-medium text-gray-700', {
              'text-sm': labelSize === 'small'
            })}
            htmlFor={name}
          >
            {label}
          </label>
        )}
        {description && (
          <div>
            <p className="text-sm italic text-gray-500 font-light mt-1">
              {description}
            </p>
          </div>
        )}
        <div className="relative">
          {iconLeft && (
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              {React.cloneElement(iconLeft, {
                className: cn(
                  'h-5 w-5 text-gray-400',
                  iconLeft.props.className
                ),
                'area-hidden': true
              })}
            </div>
          )}
          {inputAdornment && (
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <span className="text-gray-500 pt-1">{inputAdornment}</span>
            </div>
          )}
          <input
            {...inputProps}
            ref={inputRef}
            autoComplete={autoComplete}
            className={cn(
              'mt-1 border border-solid border-gray-300 p-2 outline-none-mouse focus:ring-orange-500 focus:border-orange-500 shadow-sm rounded-md scroll-offset',
              {
                'text-gray-500': inputProps.disabled,
                'ring-red-500 border-red-500': error,
                'w-full': fullWidth,
                'pl-10': !!iconLeft,
                'pr-10': !!iconRight,
                'pl-7 pr-12': inputAdornment
              },
              inputProps.className
            )}
            id={name}
            name={name}
            onFocus={(e) => {
              inputRef?.current?.scrollIntoView({
                block: 'nearest'
              })
              if (inputProps.onFocus) {
                inputProps.onFocus(e)
              }
            }}
            placeholder={placeholder}
            type={type || 'text'}
          />
          {iconRight && (
            <div className="absolute inset-y-0 right-0 pr-3 pt-1 flex items-center pointer-events-none">
              {React.cloneElement(iconRight, {
                className: cn(
                  'h-5 w-5 text-gray-400',
                  iconRight.props.className
                ),
                'area-hidden': true
              })}
            </div>
          )}
          {inputRightAdornment && inputRightAdornment}

          {error && (
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <ExclamationCircleIcon
                aria-hidden="true"
                className="h-5 w-5 text-red-500"
              />
            </div>
          )}
        </div>
        {error && (
          <div>
            <p className="text-sm italic text-red-500 font-light mt-1">
              {error}
            </p>
          </div>
        )}
        {children}
      </div>
    )
  }
)

TextField.displayName = 'TextField'

export default TextField
