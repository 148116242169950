// doesn't work in dev mode
// import { sha256 as productionSha256 } from 'crypto-hash'
import { sha256 as sha256Lib } from 'js-sha256'

export default async function sha256(message: string): Promise<string> {
  if (typeof window === 'undefined') {
    return sha256Lib(message)
  }
  if (!window.crypto || !window.crypto.subtle) {
    return sha256Lib(message)
  }

  // encode as UTF-8
  const msgBuffer = new TextEncoder().encode(message)

  // hash the message
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer)

  // convert ArrayBuffer to Array
  const hashArray = Array.from(new Uint8Array(hashBuffer))

  // convert bytes to hex string
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('')
  return hashHex
}
