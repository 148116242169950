import 'utils/rollbar'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import 'styles/tailwind.css'
import 'styles/global.css'
import { ConfirmDialogProvider } from 'components/ConfirmDialog'
import { ToastProvider } from 'components/Toast'
import { BlockUiProvider } from 'components/BlockUi'
import Modal from 'react-modal'
import { PromptDialogProvider } from 'components/PromptDialog'
import { AnalyticsScript, logPageView } from 'utils/analytics'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import 'what-input'
import { InfoDialogProvider } from 'components/InfoDialog'
import apiUrl from 'utils/apiUrl'

if (typeof window !== 'undefined') {
  Modal.setAppElement('#__next')
}

export default function App({ Component, pageProps }: AppProps) {
  const router = useRouter()

  // https://github.com/vercel/next.js/blob/canary/examples/with-react-ga/pages/_app.js
  useEffect(() => {
    // `routeChangeComplete` won't run for the first page load unless the query string is
    // hydrated later on, so here we log a page view if this is the first render and
    // there's no query string
    if (!router.asPath.includes('?')) {
      logPageView()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // Listen for page changes after a navigation or when the query changes
    router.events.on('routeChangeComplete', logPageView)
    return () => {
      router.events.off('routeChangeComplete', logPageView)
    }
  }, [router.events])

  useEffect(() => {
    const trackingParams: Record<string, string> = {}

    Object.keys(router.query).forEach((key) => {
      if (key.startsWith('utm_')) {
        trackingParams[key] = router.query[key] as string
      }
    })

    if (Object.keys(trackingParams).length > 0) {
      if (document.referrer) {
        trackingParams.referrer = document.referrer
      }
      fetch(`${apiUrl()}/track-referrer.json`, {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          web_tracking: trackingParams
        })
      })
    }

    return () => {}
  }, [router.query])

  return (
    <>
      <Head>
        {process.env.NEXT_PUBLIC_NO_INDEX ? (
          <meta content="noindex,nofollow" name="robots" />
        ) : null}
        <meta content="max-image-preview:large" name="robots" />
        <link
          href="/apple-touch-icon.png"
          rel="apple-touch-icon"
          sizes="180x180"
        />
        <link
          href="/favicon-32x32.png"
          rel="icon"
          sizes="32x32"
          type="image/png"
        />
        <link
          href="/favicon-16x16.png"
          rel="icon"
          sizes="16x16"
          type="image/png"
        />
        <meta content="initial-scale=1.0, width=device-width" name="viewport" />
        <meta content="#FF9800" name="theme-color" />
        <link
          href="/apple-icon-precomposed.png"
          rel="apple-touch-icon-precomposed"
          sizes="144×144"
        />
        <link crossOrigin="use-credentials" href={apiUrl()} rel="preconnect" />
        <title key="title">
          Best Food Trucks (BFT): Find & Book Food Trucks for Catering, Events,
          Weddings, and Office Lunches
        </title>
        <meta
          key="description"
          content="Find & Book Food Trucks for Catering, Events, Weddings, and Office Lunches"
          name="description"
        />
        <script
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `window.MSInputMethodContext && document.documentMode && document.write('<script src="https://cdn.jsdelivr.net/gh/nuxodin/ie11CustomProperties@4.1.0/ie11CustomProperties.min.js"><\\/script>');`
          }}
        />
      </Head>
      <AnalyticsScript />
      <ToastProvider>
        <InfoDialogProvider>
          <ConfirmDialogProvider>
            <PromptDialogProvider>
              <BlockUiProvider>
                <Component {...pageProps} />
              </BlockUiProvider>
            </PromptDialogProvider>
          </ConfirmDialogProvider>
        </InfoDialogProvider>
      </ToastProvider>
    </>
  )
}
