import React, { createContext, useRef, useState } from 'react'
import Button from 'components/ui/Button'
import { Dialog } from '@headlessui/react'
import cn from 'classnames'
import { CheckCircleIcon, PlusCircleIcon } from '@heroicons/react/solid'

export type InfoArgs = {
  title?: string
  content: string | React.ReactElement
  okButton?: string
  variant?: 'default' | 'error' | 'success'
}

type InfoCallbacks = {
  onClose: () => void
}

type InfoDialogContextType = {
  showInfo: (args: InfoArgs) => void
  closeInfo: () => void
}

export const InfoDialogContext = createContext<InfoDialogContextType>({
  showInfo: () => {},
  closeInfo: () => {
    // do nothing
  }
})

export const InfoDialogProvider: React.FC = ({ children }) => {
  const [open, setOpen] = useState<boolean>(false)
  const [infoArgs, setInfoArgs] = useState<InfoArgs | null>(null)
  const [infoCallbacks, setInfoCallbacks] = useState<InfoCallbacks | null>(null)
  const value = {
    showInfo: async (args: InfoArgs) => {
      setInfoArgs(args)
      setInfoCallbacks({
        onClose: () => {
          setOpen(false)
        }
      })
      setOpen(true)
    },
    closeInfo: () => {
      setOpen(false)
    }
  }
  return (
    <InfoDialogContext.Provider value={value}>
      {children}
      {open && <InfoDialog {...infoArgs!} {...infoCallbacks!} />}
    </InfoDialogContext.Provider>
  )
}

type InfoDialogProps = InfoArgs & InfoCallbacks

function InfoDialog({
  title = 'Information',
  content,
  okButton = 'OK',
  variant = 'default',
  onClose
}: InfoDialogProps) {
  const focusRef = useRef(null)

  return (
    <Dialog
      className="relative z-50"
      initialFocus={focusRef}
      onClose={onClose}
      open
    >
      <Dialog.Overlay
        className="fixed inset-0"
        style={{
          backgroundColor: 'rgba(0,0,0,0.53)'
        }}
      />
      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4">
          <Dialog.Panel className="inline-block w-full max-w-md my-8 overflow-hidden text-left align-middle transition-all bg-white shadow-xl rounded-lg">
            <div className="flex p-6">
              {variant === 'success' && (
                <CheckCircleIcon className="h-10 w-10 text-green-500 mr-4" />
              )}
              {variant === 'error' && (
                <PlusCircleIcon className="h-10 w-10 text-red-400 mr-4 rotate-45" />
              )}
              <div>
                <Dialog.Title
                  as="h3"
                  className={cn('text-xl font-medium leading-6', {
                    'text-gray-900': variant === 'default',
                    'text-red-800': variant === 'error',
                    'text-green-800': variant === 'success'
                  })}
                >
                  {title}
                </Dialog.Title>
                <div className="mt-2">{content}</div>
              </div>
            </div>

            <div
              className={cn('px-4 py-3 sm:px-6 flex justify-end bg-gray-100')}
            >
              <div className="ml-3" style={{ minWidth: '25%' }}>
                <Button
                  ref={focusRef}
                  color="primary"
                  onClick={onClose}
                  size="normal"
                  variant="contained"
                  fullWidth
                >
                  {okButton}
                </Button>
              </div>
            </div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  )
}
