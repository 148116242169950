import React, { useContext } from 'react'
import cn from 'classnames'
import { ShiftPageContext } from 'components/pages/ShiftPage/ShiftPageProvider'

export type ButtonStyle = {
  fullWidth?: boolean
  color: 'primary' | 'secondary' | 'danger' | 'default'
  variant: 'outline' | 'contained' | 'transparent' | 'anchor'
  size?: 'small' | 'medium' | 'normal' | 'large' | 'input'
  shadow?: boolean
  albertsons?: boolean
  className?: string
}

export type ButtonProps = {
  children: React.ReactElement | string | (React.ReactElement | string)[]
  disabled?: boolean
  isAlbertsons?: boolean
} & ButtonStyle

export const buttonClass = ({
  fullWidth,
  size = 'normal',
  variant,
  color,
  shadow,
  albertsons,
  className
}: ButtonStyle): string =>
  cn(
    'group inline-flex items-center justify-center font-medium outline-none-mouse transition-all duration-200 disabled:cursor-not-allowed',
    className,
    {
      'rounded-md': !albertsons,
      'rounded-btn-albertsons': albertsons,
      'w-full': fullWidth,
      'w-auto': !fullWidth,
      'shadow-sm':
        shadow &&
        (size === 'medium' || size === 'small' || size === 'input') &&
        variant !== 'anchor',
      'shadow-md':
        shadow &&
        (size === 'normal' || size === 'large') &&
        variant !== 'anchor',
      'text-sm px-4 py-1 h-8': size === 'small' && variant !== 'anchor',
      'text-sm px-4 py-2': size === 'medium' && variant !== 'anchor',
      'text-sm px-3 py-[0.70rem]': size === 'input' && variant !== 'anchor',
      'text-base px-4 sm:px-6 py-3 min-h-10':
        size === 'normal' && variant !== 'anchor',
      'text-lg p-6 h-14': size === 'large' && variant !== 'anchor',

      'text-sm p-0':
        (size === 'small' || size === 'medium' || size === 'input') &&
        variant === 'anchor',
      'text-base p-0': size === 'normal' && variant === 'anchor',
      'text-lg p-0': size === 'large' && variant === 'anchor',

      // primary contained
      'bg-orange-600 hover:bg-orange-700 text-white disabled:bg-orange-200':
        color === 'primary' && variant === 'contained' && !albertsons,
      // primary outline
      'border border-solid border-orange-500 bg-white text-orange-600 hover:text-orange-800 hover:bg-orange-50 outline-none-mouse focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 disabled:border-orange-200 disabled:text-orange-300 disabled:hover:bg-white':
        color === 'primary' && variant === 'outline' && !albertsons,
      'text-orange-600 hover:text-orange-800 outline-none-mouse disabled:text-orange-300':
        color === 'primary' && variant === 'anchor' && !albertsons,

      'bg-albertsons-jam hover:bg-albertsons-sangria text-white disabled:bg-albertsons-secondary1':
        color === 'primary' && variant === 'contained' && albertsons,
      'border border-solid border-albertsons-neutral5 bg-white text-albertsons-jam hover:text-albertsons-sangria hover:bg-albertsons-neutral7 outline-none-mouse focus:ring-2 focus:ring-offset-2 focus:ring-orange-500':
        color === 'primary' && variant === 'outline' && albertsons,

      'bg-gray-200 hover:bg-gray-300 text-gray-600 disabled:bg-gray-200 disabled:text-gray-400':
        color === 'secondary' && variant === 'contained' && !albertsons,
      'border border-solid border-gray-300 bg-white text-gray-600 outline-none-mouse focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 hover:bg-gray-50':
        color === 'secondary' && variant === 'outline' && !albertsons,

      'bg-albertsons-neutral7 hover:bg-albertsons-neutral6 text-albertsons-jam':
        color === 'secondary' && variant === 'contained' && albertsons,
      'border border-solid border-albertsons-neutral5 bg-white text-albertsons-jam outline-none-mouse focus:ring-2 focus:ring-offset-2 focus:ring-albertsons-neutral5 hover:bg-albertsons-neutral6':
        color === 'secondary' && variant === 'outline' && albertsons,

      // blue secondary contained
      'bg-blue-600 hover:bg-blue-700 text-white disabled:bg-blue-200':
        color === 'default' && variant === 'contained' && !albertsons,
      // blue secondary outline
      'border border-solid border-blue-500 bg-white text-blue-600 hover:text-blue-800 hover:bg-blue-50 outline-none-mouse focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:border-blue-200 disabled:text-blue-300 disabled:hover:bg-white':
        color === 'default' && variant === 'outline' && !albertsons,
      // blue secondary anchor
      'underline text-blue-600 hover:text-blue-800 outline-none-mouse disabled:text-blue-300 underline':
        color === 'default' && variant === 'anchor',

      'bg-red-600 hover:bg-red-700 text-white disabled:bg-red-300':
        color === 'danger' && variant === 'contained',
      'border border-solid border-red-600 bg-white text-gray-600 hover:bg-red-50 outline-none-mouse focus:ring-2 focus:ring-offset-2 focus:ring-red-500':
        color === 'danger' && variant === 'outline',

      'bg-transparent text-gray-200 border-gray-400 border-2 border-solid hover:text-white hover:border-gray-300 transition-all duration-200':
        color === 'secondary' && variant === 'transparent',

      'text-gray-200 border-2 border-none hover:text-white hover:border-gray-300 transition-all duration-200 underline':
        color === 'secondary' && variant === 'anchor',

      'text-red-500 border-2 border-none hover:text-red-700 hover:border-gray-300 transition-all duration-200 underline':
        color === 'danger' && variant === 'anchor'
    }
  )

type ButtonComponentProps = ButtonProps &
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >

const Button = React.forwardRef<HTMLButtonElement, ButtonComponentProps>(
  (
    {
      children,
      fullWidth,
      size = 'normal',
      variant,
      shadow,
      color,
      type = 'button',
      className,
      isAlbertsons,
      ...props
    }: ButtonComponentProps,
    forwardedRef
  ) => {
    const albertsons = useContext(ShiftPageContext).albertsons || isAlbertsons

    return (
      // eslint-disable-next-line react/button-has-type
      <button
        ref={forwardedRef}
        // eslint-disable-next-line react/button-has-type
        className={buttonClass({
          color,
          fullWidth,
          size,
          variant,
          shadow,
          albertsons,
          className
        })}
        // eslint-disable-next-line react/button-has-type
        type={type}
        {...props}
      >
        {children}
      </button>
    )
  }
)

Button.displayName = 'Button'

export default Button
