import React, { createContext, useState } from 'react'
import Button, { ButtonStyle } from 'components/ui/Button'
import TextField from 'components/ui/TextField'
import Modal from 'react-modal'
import cn from 'classnames'

export type PromptDialogArgs = {
  title?: string
  fieldLabel: string
  placeholder?: string
  message?: string
  okButton?: string
  cancelButton?: string
  color?: ButtonStyle['color']
}

type PromptDialogCallbacks = {
  onCancel: () => void
  onSuccess: (value: string) => void
}

type PromptDialogContextType = {
  prompt: (args: PromptDialogArgs) => Promise<string>
  close: () => void
}

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderWidth: 0,
    margin: 0,
    padding: 0,
    overflow: 'hidden'
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.53)',
    zIndex: 1000
  }
}

export const PromptDialogContext = createContext<PromptDialogContextType>({
  prompt: async () => '',
  close: () => {
    // do nothing
  }
})

export const PromptDialogProvider: React.FC = ({ children }) => {
  const [open, setOpen] = useState<boolean>(false)
  const [
    confirmationArgs,
    setConfirmationArgs
  ] = useState<PromptDialogArgs | null>(null)
  const [
    confirmCallbacks,
    setConfirmationCallbacks
  ] = useState<PromptDialogCallbacks | null>(null)
  const value = {
    prompt: async (args: PromptDialogArgs): Promise<string> => {
      return new Promise((resolve, reject) => {
        setConfirmationArgs(args)
        setConfirmationCallbacks({
          onSuccess: (fieldValue) => {
            setOpen(false)
            resolve(fieldValue)
          },
          onCancel: () => {
            setOpen(false)
            reject()
          }
        })
        setOpen(true)
      })
    },
    close: () => {
      setOpen(false)
    }
  }
  return (
    <PromptDialogContext.Provider value={value}>
      {children}
      {open && <PromptDialog {...confirmationArgs!} {...confirmCallbacks!} />}
    </PromptDialogContext.Provider>
  )
}

type PromptDialogProps = PromptDialogArgs & PromptDialogCallbacks

function PromptDialog({
  title = 'Are you sure?',
  message,
  okButton = 'Confirm',
  cancelButton = 'Cancel',
  color = 'primary',
  fieldLabel,
  placeholder,
  onCancel,
  onSuccess
}: PromptDialogProps) {
  const [value, setValue] = useState<string>('')
  return (
    <Modal onRequestClose={() => onCancel()} style={modalStyles} isOpen>
      <div className="rounded-lg text-left overflow-hidden shadow-xl max-w-lg w-full min-w-[90vw] md:min-w-fit">
        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div className="flex items-start">
            <div className="mt-3 sm:mt-0 sm:ml-4 sm:text-left">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                {title}
              </h3>
              <div className="mt-4 mb-4">
                <p className="text-sm text-gray-500">{message}</p>
              </div>
              <TextField
                label={fieldLabel}
                name={fieldLabel}
                onChange={(event) => setValue(event.target.value)}
                placeholder={placeholder}
                value={value}
              />
            </div>
          </div>
        </div>
        <div
          className={cn('px-4 py-3 sm:px-6 flex justify-between bg-gray-100')}
        >
          <div className="sm:w-auto w-1/2">
            <Button
              color="secondary"
              onClick={onCancel}
              size="small"
              variant="outline"
              fullWidth
            >
              {cancelButton}
            </Button>
          </div>
          <div className="ml-3 sm:w-auto w-1/2">
            <Button
              color={color}
              disabled={!value.trim()}
              onClick={() => {
                if (value.trim()) {
                  onSuccess(value!)
                }
              }}
              size="small"
              variant="contained"
              fullWidth
            >
              {okButton}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
