/* eslint-disable no-await-in-loop */
import Script from 'next/script'
import React from 'react'
import Head from 'next/head'

import rollbar from './rollbar'
import sha256 from './sha256'
import cleanPhone from './cleanPhone'

const ENABLED = process.env.NODE_ENV === 'production'

const IS_SERVER = typeof window === 'undefined'

export function AnalyticsScript() {
  if (!ENABLED) return <script />

  return (
    <>
      <Head>
        {/* https://nextjs.org/docs/messages/no-before-interactive-script-outside-document */}
        <script
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `
           window.dataLayerPending = []
           
           function gtag(){
            window.dataLayerPending.push(arguments)
            if (window.dataLayer) {
              while(window.dataLayerPending.length > 0) {
                var action = window.dataLayerPending.shift()
                window.dataLayer.push(action)
              }
            }
           }
          `
          }}
        />
      </Head>
      <Script
        src="https://www.googletagmanager.com/gtag/js?id=G-7FQCWZ8F3X"
        strategy="afterInteractive"
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          gtag('js', new Date());

          gtag('config', 'G-7FQCWZ8F3X');
          // gtag('config', 'AW-768772864');
        `}
      </Script>
      <Script id="meta-pixel" strategy="afterInteractive">
        {`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '628919715981294');
          fbq('track', 'PageView');
        `}
      </Script>
      <Script id="linked-in-tracking" strategy="afterInteractive">
        {`
          _linkedin_partner_id = "5524354"; window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || []; window._linkedin_data_partner_ids.push(_linkedin_partner_id);
          
           (function(l) { if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])}; window.lintrk.q=[]} var s = document.getElementsByTagName("script")[0]; var b = document.createElement("script"); b.type = "text/javascript";b.async = true; b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js"; s.parentNode.insertBefore(b, s);})(window.lintrk);
        `}
      </Script>
      <Script id="bing-tracking" strategy="afterInteractive">
        {`
            (function(w,d,t,r,u)
            {
              var f,n,i;
              w[u]=w[u]||[],f=function()
              {
                var o={ti:"97106542", enableAutoSpaTracking: true};
                o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")
              },
              n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function()
              {
                var s=this.readyState;
                s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)
              },
              i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)
            })
            (window,document,"script","//bat.bing.com/bat.js","uetq");
        `}
      </Script>
      <noscript>
        {/* eslint-disable-next-line jsx-a11y/alt-text, @next/next/no-img-element */}
        <img
          height="1"
          src="https://www.facebook.com/tr?id=628919715981294&ev=PageView&noscript=1"
          style={{ display: 'none' }}
          width="1"
        />
      </noscript>
      <noscript>
        <img
          alt=""
          height="1"
          src="https://px.ads.linkedin.com/collect/?pid=5524354&fmt=gif"
          style={{ display: 'none' }}
          width="1"
        />
      </noscript>
    </>
  )
}

export const logPageView = () => {
  if (!ENABLED) return

  if (IS_SERVER) return

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { gtag, fbq } = window as any

  if (gtag) {
    gtag('config', 'G-7FQCWZ8F3X', {
      page_path: window.location.pathname
    })
  }
  if (fbq) {
    fbq('track', 'PageView')
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const logEvent = (type: string, payload: any) => {
  if (!ENABLED) {
    // eslint-disable-next-line
    console.log({
      gtag: 'event',
      type,
      payload
    })
    return
  }

  if (IS_SERVER) return

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { gtag } = window as any

  if (!gtag) return

  try {
    gtag('event', type, payload)
  } catch (e) {
    rollbar.warn(e as Error)
  }
}

type GoogleTagUserData = {
  email: string
  phone?: string | null
  firstName?: string | null
  lastName?: string | null
}

const normalizeText = (text: string) => text.toLowerCase().trim()

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setAdsUserData = async (
  ...userDatas: (GoogleTagUserData | null)[]
) => {
  const payload: {
    sha256_email_address: string[]
    sha256_phone_number?: string[]
    address?: {
      sha256_first_name: string
      sha256_last_name: string
    }[]
  } = {
    sha256_email_address: []
  }

  try {
    for (const userData of userDatas) {
      if (!userData) {
        // eslint-disable-next-line no-continue
        continue
      }
      payload.sha256_email_address.push(
        await sha256(normalizeText(userData.email))
      )
      if (userData.phone) {
        payload.sha256_phone_number = payload.sha256_phone_number || []
        payload.sha256_phone_number.push(
          await sha256(`+1${cleanPhone(userData.phone)}`)
        )
      }
      if (userData.firstName && userData.lastName) {
        payload.address = payload.address || []
        payload.address.push({
          sha256_first_name: await sha256(normalizeText(userData.firstName)),
          sha256_last_name: await sha256(normalizeText(userData.lastName))
        })
      }
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
    rollbar.error(e as Error)
    return
  }

  if (!ENABLED) {
    // eslint-disable-next-line
    console.log({
      gtag: 'set',
      type: 'user_data',
      payload
    })
    return
  }

  if (IS_SERVER) return

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { gtag } = window as any

  if (!gtag) return

  try {
    gtag('set', 'user_data', payload)
  } catch (e) {
    rollbar.warn(e as Error)
  }
}
