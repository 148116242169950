import React, { useContext } from 'react'
import cn from 'classnames'
import { ShiftPageContext } from 'components/pages/ShiftPage/ShiftPageProvider'

type Props = {
  className?: string
  text?: string
  size?: 'small' | 'base' | 'large'
}

const Loading = ({ className, text = 'Loading...', size = 'base' }: Props) => {
  const { albertsons } = useContext(ShiftPageContext)
  return (
    <div
      className={cn(
        'flex items-center justify-center',
        {
          'my-10': size === 'base'
        },
        className
      )}
    >
      <svg
        className={cn('animate-spin mr-5', {
          'h-12 w-12': size === 'large',
          'h-8 w-8': size === 'base',
          'h-6 w-6': size === 'small',
          'text-orange-500': !albertsons,
          'text-albertsons-jam': albertsons
        })}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        />
        <path
          className="opacity-75"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          fill="currentColor"
        />
      </svg>
      <p
        aria-busy="true"
        className="text-lg leading-6 font-medium text-gray-500"
        role="alert"
      >
        {text}
      </p>
    </div>
  )
}

export default Loading
